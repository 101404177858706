import React from "react";

export default function Brandsbg() {
  return (
    <div className="brandsbg">
      <div className="container">
        <div className="brandbg-container">
          {/* <h1 className="brand_brudcrumb" style={{color:"#000"}}>Our Brands</h1> */}
        </div>
      </div>
    </div>
  );
}
