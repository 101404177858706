import React from 'react'

const Bread = () => {
  return (
    <div className='bread'>
         <h1 className="brand_brudcrumb" style={{color:"#000"}}>Babolat Retail Flagship Store</h1>
         <p className='brand_brudcrumb'>Dubai UAE</p>
    </div>
  )
}

export default Bread