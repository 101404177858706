import React from "react";

export default function Directbg() {
  return (
    <div className="directbg">
      <div className="container">
        <div className="directbg-container">
          {/* <h1>Direct Retail</h1> */}
        </div>
      </div>
    </div>
  );
}
