import Partnergallery from "./Partnergallery";
import Partnerbg from "./partnerbg";
import Bread from "./bread";
import Gllery from './partnersgallery'

function PartnerPage() {
  return (
    <div>
      <Partnerbg />
      <Bread/>
      {/* <Partnergallery /> */}
      <Gllery/>
    </div>
  );
}

export default PartnerPage;
