import Main from ".//ecombg";
import Ecomgallery from "./ecomgallery";
import Bread from "./bread";

function DirectPage() {
  return (
    <div>
      <Main />
      <Bread/>
      <Ecomgallery />
    </div>
  );
}

export default DirectPage;
