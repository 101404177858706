import React from 'react'

const Bread = () => {
  return (
    <div className='bread'>
         <h1 className="brand_brudcrumb" style={{color:"#000"}}>Our Brands</h1>
         {/* <p className='bread_p'>Empowering Diversity, Uniting Excellence: Our Diverse Portfolio of Brands Redefining MENA's Future.</p> */}
    </div>
  )
}

export default Bread