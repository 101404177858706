import React from "react";

export default function Partnerbg() {
  return (
    <div className="partnerbg">
      <div className="container">
        <div className="partnerbg-container">
          {/* <h1>Partners</h1> */}
        </div>
      </div>
    </div>
  );
}
